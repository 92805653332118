//import { useEffect, useState } from "react";

import DynamicLoader from "./DynamicLoader";

//const Contact = ({selections, setSelections, colorCheck, selectionCheck }) => {
const Contact = ({selections, setSelections }) => {


    //USE app.js FUNCTION INSTEAD OF CUSTOM
    function selectionCheck(ev, text) {
        var pi, qi
        var id = ev.currentTarget.id
        pi = id.indexOf('.', 0)
        var page = parseInt(id.substring(0 , pi))
        qi = id.indexOf('.', pi+1)
        var question = parseInt(id.substring(pi+1, qi))
        var choice = parseInt(id.substring(4, id.length))
        //console.log("id-array",selections.pages[page].questions[question]);
        //console.log("id",page, question, choice);
        //console.log("id-value",selections.pages[page].questions[question].choices[choice].value);
        if (ev.type === "click") { //if (! text) {
            selections.pages[page].questions[question].choices[choice].value = !selections.pages[page].questions[question].choices[choice].value
        }
        else {
            selections.pages[page].questions[question].choices[choice].value = ev.currentTarget.value
        }

            setSelections({
        ...selections, choices:selections.choices
        })
        //console.log("cC2 ",selections.pages[page].questions[question].choices[choice].value);

        //colorCheck();
    }


    function handleSubmit(ev) {
        console.log(ev);
        //console.log(JSON.stringify(selections))
        ev.preventDefault(); // prevent page load

    }

    // function handleSubmit(ev) {
    //     console.log(ev);
    //     console.log(typeof(JSON.stringify(selections)))
    //     ev.preventDefault(); // prevent page load
    //     db_post("leads");
    // }

    // useEffect(() => {
    //     colorCheck();
    //     //console.log("pageInc ", page, navArray[page]);
    // }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const API_HOST = "http://127.0.0.1/api"


    // function db_get(endpoint){
    //     console.log("GET " + API_HOST);
    //     fetch(API_HOST + "/" + endpoint)
    //     .then(res => res.json())
    //     .then(
    //         (result) => {
    //         console.log(result);
    //         //console.log(result[0]["lead_json"]);
    //         },
    //         // Note: it's important to handle errors here
    //         // instead of a catch() block so that we don't swallow
    //         // exceptions from actual bugs in components.
    //         (error) => {
    //         console.log(error);
    //         }
    //     )
    // }

    // function db_put(endpoint, id, req_json){
    //     const dev_json =
    //       {"client_json":{
    //           "name": "App PUT Test",
    //           "logo_path": "/testclient/logo.jpg",
    //           "css_path": "/testclient/main.css",
    //           "questions_json": "Please insert questions_JSON"
    //         }
    //       ,
    //       "lead_json":{
    //           "client_id": 7,
    //           "lead_json": "PUT TEST lead_results_json_placeholder"
    //       }
    //     };
    //     const requestOptions = {
    //       method: 'PUT',
    //       headers: { 'Content-Type': 'application/json' },
    //       body: JSON.stringify(dev_json[req_json])
    //     };
    //     fetch(API_HOST + '/' + endpoint + "/" + id, requestOptions)
    //       .then(response => console.log(response));
    //   }


    function db_post(endpoint){
        const req_json =
        {
            "client_id": 7,
            "lead_json": JSON.stringify(selections)
        };

        const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_json)
        };
        fetch(API_HOST + '/' + endpoint, requestOptions)
        .then(response => console.log(response.json()));
    }


    return (
        <div className="mx-4 ">
            <form onSubmit={(ev) => handleSubmit(ev)}>
                <DynamicLoader
                    s={selections}
                    pageID={6}
                    selectionCheck = { (data) => selectionCheck(data) }
                />

                <input
                    type="submit"
                    value="Abschicken"
                    className="btn btn-lg btn-block btn-outline-primary"
                >
                </input>
            </form>

            {/* <div>
                <h1>Wie können wir Sie erreichen?</h1>
            </div>
            <div>
                <h2>Beratungstermin vereinbaren</h2>
            </div>
            <div>

                <form onSubmit={(ev) => handleSubmit(ev)}>
                <div>
                        <label>Anrede </label>
                        <select
                            id="7.0.0"
                            className="choice"
                            value={selections.pages[7].questions[0].choices[0].value ? selections.pages[7].questions[0].choices[0].value : ""}
                            onChange={(ev) => selectionCheck(ev, true)}
                        >
                            <option value="Keine Angabe">Keine Angaben</option>
                            <option value="Herr">Herr</option>
                            <option value="Frau">Frau</option>
                        </select>
                    </div>
                    <div>
                        <input
                            id="7.0.1"
                            className="choice form-textarea"
                            value={selections.pages[7].questions[0].choices[1].value ? selections.pages[7].questions[0].choices[1].value : ""}
                            onChange={(ev) => selectionCheck(ev)}
                            placeholder="Vorname*"
                            type="text">
                        </input>
                    </div>
                    <div>
                        <input
                            id="7.0.2"
                            className="choice  form-textarea"
                            value={selections.pages[7].questions[0].choices[2].value ? selections.pages[7].questions[0].choices[2].value : ""}
                            onChange={(ev) => selectionCheck(ev, true)}
                            placeholder="Nachname*"
                            type="text">
                        </input>
                    </div>
                    <div>
                        <input
                            id="7.0.3"
                            className="choice  form-textarea"
                            value={selections.pages[7].questions[0].choices[3].value ? selections.pages[7].questions[0].choices[3].value : ""}
                            onChange={(ev) => selectionCheck(ev, true)}
                            placeholder="Telefon*"
                            type="text">
                        </input>
                    </div>
                    <div>
                        <input
                            id="7.0.4"
                            className="choice  form-textarea"
                            value={selections.pages[7].questions[0].choices[4].value ? selections.pages[7].questions[0].choices[4].value : ""}
                            onChange={(ev) => selectionCheck(ev, true)}
                            placeholder="E-Mail*"
                            type="text">
                        </input>
                    </div>
                    <div>
                        <input
                            id="7.0.5"
                            className="choice  form-textarea"
                            value={selections.pages[7].questions[0].choices[5].value ? selections.pages[7].questions[0].choices[5].value : ""}
                            onChange={(ev) => selectionCheck(ev, true)}
                            placeholder="Straße / Hausnummer"
                            type="text">
                        </input>
                    </div>
                    <div>
                        <input
                            id="7.0.6"
                            className="choice  form-textarea"
                            value={selections.pages[7].questions[0].choices[6].value ? selections.pages[7].questions[0].choices[6].value : ""}
                            onChange={(ev) => selectionCheck(ev, true)}
                            placeholder="Postleitzahl / Ort"
                            type="text">
                        </input>
                    </div>
                    <div>
                        <label>Wunsch-Standort angeben*</label>
                        <select
                            id="7.1.0"
                            className="choice"
                            value={selections.pages[7].questions[1].choices[0].value ? selections.pages[7].questions[1].choices[0].value : ""}
                            onChange={(ev) => selectionCheck(ev, true)}
                        >
                            <option value="Bitte wählen">Bitte wählen</option>
                            <option value="Video-Beratung">Video-Beratung</option>
                            <option value="Standort 1">Standort 1</option>
                            <option value="Standort 2">Standort 2</option>
                            <option value="Standort n">Standort n</option>
                        </select>
                    </div>
                    <div>
                        <label>Ihr Wunschberater</label>
                        <input  type="text"
                                readOnly="readonly"
                                autoComplete="off">
                        </input>
                    </div>
                    <div>
                        <label>Ihr Wunschtermin*</label>
                        <input  type="text"
                                readOnly="readonly"
                                autoComplete="off">
                        </input>
                    </div>
                    <div className="p-05">
                        <textarea
                            id="7.3.0"
                            className="choice  form-textarea"
                            value={selections.pages[7].questions[3].choices[0].value ? selections.pages[7].questions[4].choices[0].value : ""}
                            onChange={(ev) => selectionCheck(ev, true)}
                            placeholder="Möchten Sie uns zu Ihrem Beratungs-Termin
                        vorab noch etwas mitteilen?">
                        </textarea>
                    </div>
                    <input
                        type="submit"
                        value="Abschicken"
                        className="p-05"
                    >
                    </input>
                </form>
            </div>  */}
        </div>
    )
}

export default Contact
