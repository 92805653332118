import { useLocation, useHistory } from 'react-router-dom'
import { useEffect } from "react";
import Button from './Button'


//const pageIndex = 1

const Navigation = ({page, incButton, decButton, disableForwardBtn, forwardBtn, selections }) => {
    const location = useLocation()
    const history = useHistory();
    //const [page, setPage] = useState(pageIndex)

    const navArray = [
        "/",
        "Form",
        "Persons",
    //    "Color",
        "Style",
        "Wishes",
        "Budget",
        "Data",
        "Contact"
    ]


    useEffect(() => {
        history.push(navArray[page]);
        //console.log(page, location.pathname, "Navigation")
        //console.log("navigation, fB: ", forwardBtn);
        //console.log("pageInc ", page, navArray[page]);
    }, [page, selections]) // eslint-disable-line react-hooks/exhaustive-deps


    // function incButton() {
    //     console.log("page", page)
    //     return setPage(page + 1);

    //     //console.log("incBtn_loc: ", location.pathname)
    //     //history.push(navArray[page]);
    // }

    // function decButton() {
    //     console.log("page", page)
    //     return setPage(page + 1);

    // }




    let backButton;
    if(location.pathname !== "/" && location.pathname !== "/Form") {
        backButton =
        <div className="col-6 col-lg-3">
            <Button
                    className = {"btn btn-block btn-lg btn-primary"}
                    color={"white"}
                    textColor={"white"}
                    text={"Zurück"}
                    onClick={decButton}
                    name={"backward"}

                />
                </div>
    }


    let forwardButton;
    if(location.pathname !== "/Contact") {
        forwardButton =
        <div className="col-6 col-lg-3">
        <Button
            className = {"btn btn-block btn-lg"}
            bgcolor={ selections.pages[page-1].disable_forward ? "lightgrey" : "var(--client-color)"}
            color={"white"}
            text={location.pathname === "/Contact" ? "Absenden" : "Weiter"}
            //onClick={() => handlePage('INCREMENT')}
            onClick={incButton}
            name={"forward"}
            //disabled={ forwardBtn }
            disabled = { selections.pages[page-1].disable_forward }
        />
        </div>
    }

    return (

        <div className="row d-flex justify-content-center mt-5">

                { backButton }


                {forwardButton}

        </div>
    )
}

export default Navigation
