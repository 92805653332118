import { useEffect } from "react";

import DynamicLoader from "./DynamicLoader";

const Wishes = ({selections, setSelections, colorCheck, selectionCheck, checkForward}) => {
    
    useEffect(() => {
        colorCheck();
        //console.log("pageInc ", page, navArray[page]);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    
    return (
        <div>

            <DynamicLoader 
                s={selections}
                pageID={3}
                selectionCheck = { (data) => selectionCheck(data) } 
            />


            {/* <div>
                <h1>Welche Wünsche sind zu beachten?</h1>
                <p>(Optional) Mehrfachauswahl möglich</p>
            </div> 
            <div className="flex flex-wrap justify-center -m-05 mx-4">
                <div className="flex flex-wrap flex-col p-05">
                    <h4>Integrierter Kochfeldabzug</h4>
                    <div>
                        <button type="button"
                            className="choice cursor-pointer border-Default"
                            id="4.0.0"
                            onClick={(ev) => selectionCheck(ev)} 
                        >
                            <img src={image1} alt=""></img>
                        </button>
                    </div>
                </div>
                <div className="flex flex-wrap flex-col p-05">
                    <h4>Küchengeräte auf Sichthöhe</h4>
                    <div>
                        <button type="button"
                            className="choice cursor-pointer border-Default"
                            id="4.0.1"
                            onClick={(ev) => selectionCheck(ev)} 
                        >
                            <img src={image2} alt=""></img>
                        </button>
                    </div>
                </div>
                <div className="flex flex-wrap flex-col p-05">
                    <h4>Arbeitsplatte aus Granit</h4>
                    <div>
                        <button type="button"
                            className="choice cursor-pointer border-Default"
                            id="4.0.2"
                            onClick={(ev) => selectionCheck(ev)} 
                        >
                            <img src={image3} alt=""></img>
                        </button>
                    </div>
                </div>
                <div className="flex flex-wrap flex-col p-05">
                    <h4>Essplatzlösung</h4>
                    <div>
                        <button type="button"
                            className="choice cursor-pointer border-Default"
                            id="4.0.3"
                            onClick={(ev) => selectionCheck(ev)} 
                        >
                            <img src={image4} alt=""></img>
                        </button>
                    </div>
                </div>
                <div className="flex flex-wrap flex-col p-05">
                    <h4>Platz für Vorräte</h4>
                    <div>
                        <button type="button"
                            className="choice cursor-pointer border-Default"
                            id="4.0.4"
                            onClick={(ev) => selectionCheck(ev)} 
                        >
                            <img src={image5} alt=""></img>
                        </button>
                    </div>
                </div>
                <div className="flex flex-wrap flex-col p-05">
                    <h4>Waschmaschine</h4>
                    <div>
                        <button type="button"
                            className="choice cursor-pointer border-Default"
                            id="4.0.5"
                            onClick={(ev) => selectionCheck(ev)} 
                        >
                            <img src={image6} alt=""></img>
                        </button>
                    </div>
                </div>
                <div className="flex flex-wrap flex-col p-05">
                    <h4>Wäschetrockner</h4>
                    <div>
                        <button type="button"
                            className="choice cursor-pointer border-Default"
                            id="4.0.6"
                            onClick={(ev) => selectionCheck(ev)} 
                        >
                            <img src={image7} alt=""></img>
                        </button>
                    </div>
                </div>
                <div className="flex flex-wrap flex-col p-05">
                    <h4>Putzschrank</h4>
                    <div>
                        <button type="button"
                            className="choice cursor-pointer border-Default"
                            id="4.0.7"
                            onClick={(ev) => selectionCheck(ev)} 
                        >
                            <img src={image8} alt=""></img>
                        </button>
                    </div>
                </div>
                <div className="flex flex-wrap flex-col p-05">
                    <h4>Weitere Wünsche</h4>
                    <textarea 
                        id="4.0.8"
                        className="choice  form-textarea"
                        rows="8" 
                        value={selections.pages[4].questions[0].choices[8].value ? selections.pages[4].questions[0].choices[8].value : ""}
                        onChange={(ev) => selectionCheck(ev, true)}
                    >    
                    </textarea>
                </div>
            </div>            */}
        </div>
    )
}

export default Wishes
