import { useEffect } from "react";



const DynamicLoader = ({s, pageID, selectionCheck }) => {

    //let pageID = 0;

    const aPath = s.pages[pageID].assets_path;
    let meas_counter = 0;

    for(let i=0; i < s.pages.length; i++) {
        let navLinkInd = document.getElementById(i)
        //console.log("HELP ME", document.getElementById(i) )
        if( navLinkInd ) {
            if( navLinkInd.className.includes("btn-active") ) {
                navLinkInd.className = navLinkInd.className.replace("btn-active", "btn-visited");
                var spansInd = navLinkInd.getElementsByTagName("span");
                spansInd[0].className = "d-none d-sm-inline";
            }
        }
    }

    const navLink = document.getElementById(pageID)
    if(navLink) {
        if( navLink.className.includes("disabled-link") ) {
            navLink.className = navLink.className.replace("disabled-link", "");
        }
        if( navLink.className.includes("btn-visited") && !navLink.className.includes("btn-active") ) {
            navLink.className = navLink.className.replace("btn-visited", "btn-active");
        }
        if( navLink.className.includes("btn-deactive") ) {
            navLink.className = navLink.className.replace("btn-deactive", "btn-active");
            // navLink.className = navLink.className.replace("btn-deactive", "btn-visited");
        }
        if( navLink.className.includes("btn-active") ) {
            var spans = navLink.getElementsByTagName("span");
            spans[0].className = "d-inline";
        }

    }


    return (
        <div className="mt-4">
            {s.pages[pageID].pre_headline ?
                <h2 className="text-center text-secondary mb-0" >{s.pages[pageID].pre_headline}</h2>: null
            }
            {
                // QUESTIONS LOOP
                s.pages[pageID].questions.map( (question, q_ind) => (

                    <div className="question-section mb-4">
                        {q_ind % 2 ? <hr className="mb-4"/> : ""}
                        <div className="mt-1 ">
                            <h2 className="text-center" >{question.title}</h2>
                            {question.description ?
                                <h3 className="text-center" >{question.description}</h3>: "" }
                        </div>
                        <div className="w-100 justify-content-center">
                            <div className={question.className ? (question.className) : ("flex flex-wrap justify-content-center mt-4") } >


                                {/* CHOICES LOOP */}
                                {question.choices.map((c, c_ind) => (
                                    <div className=
                                            {c.className ?
                                            c.className
                                            : "p-2 flex" } >
                                        <div className={ c.width ? c.width : "w-40 flex flex-column" } >
                                            {(c.type==="btn_img" || c.type==="textarea" || c.type==="upload" || c.type==="upload_btn") && ! c.title
                                                ? <p className="flex-fill text-center mb-0">{c.name}</p>
                                                : ""
                                            }
                                            {c.title === "." ? <span className="text-center d-block text-white mb-2">&zwnj;</span> : ""}

                                            {c.description ? <span>{c.description}</span> : "" }


                                            {/* {c.type==="btn" || c.type==="btn_img" || c.type==="btn_upload" ?  */}
                                            {c.type.includes("btn") ?
                                            (<div className="mt-2">
                                            <button
                                                className={ c.btnClass ? c.btnClass : "p-0 btn choice border-Default"}
                                                type="button"
                                                id={c.id}
                                                onClick={(ev) => selectionCheck(ev)}
                                                act={ c.act ? c.act : ""}
                                                >
                                                {c.type==="btn_img" ? ("") : (<span className={c.class}>{c.name}</span>) }
                                                {c.type==="btn_img" || c.type==="upl_btn" ? (<img src={aPath + c.asset} alt={c.name} className={c.img_class ? c.img_class : "img-fluid" } ></img>) : "" }
                                                {c.type==="upl_btn" ? (
                                                    <input multiple="multiple"
                                                        id={c.id}
                                                        accept="image/*"
                                                        type="file"
                                                        onClick={(ev) => selectionCheck(ev)}
                                                        className="">
                                                    </input>
                                                ): ("")}
                                            </button></div>)
                                            : ("")
                                            }


                                            {c.type==="img" ?
                                            (
                                                <img
                                                    id={c.id}
                                                    className="border-Default justify-center"
                                                    src={c.asset==="Mass-" ? (aPath+c.asset+s.data[0]) :(aPath+c.asset)}
                                                    alt={c.name}
                                                    className={c.class ? c.class : "img-fluid"}>
                                                </img>
                                            ): ("")
                                            }


                                            {c.type==="textarea" ?
                                            (
                                                <textarea
                                                    id={c.id}
                                                    className="choice form-control w-100 mt-2"
                                                    rows= { c.row ? c.row : "5" }
                                                    value={c.value ? c.value : ""}
                                                    placeholder={ c.placeholder ? c.placeholder : ""}
                                                    onChange={(ev) => selectionCheck(ev, true)}
                                                >
                                                </textarea>
                                            ): ("")
                                            }


                                            {/* { (c.type==="input_limit" && meas_counter === 0) && <div className="col-6">' } */}

                                            {c.type==="input_limit" && meas_counter < s.data[1] ?
                                            (
                                                <>
                                                <input

                                                    lim = {meas_counter+=1} // can't run the increment without random
                                                    id={c.id}
                                                    className="choice form-control"
                                                    // textarea"
                                                    value={c.value ? c.value : ""}
                                                    onChange={(ev) => selectionCheck(ev)}
                                                    placeholder={c.name}
                                                    type="text">
                                                </input>

                                                </>

                                            ): ("")
                                            }

                                            {/* { (c.type==="input_limit" && meas_counter === s.data[1]) && </div> } */}

                                            {c.type==="input" ?
                                            (
                                                    <input
                                                        id={c.id}
                                                        className="choice form-control"
                                                        value={c.value ? c.value : ""}
                                                        onChange={(ev) => selectionCheck(ev)}
                                                        placeholder={ c.placeholder ? c.placeholder : c.name }
                                                        type="text">
                                                    </input>
                                            ): ("")
                                            }

                                            {c.type==="date_input" ?
                                            (
                                                    <input
                                                        id={c.id}
                                                        className="choice form-control"

                                                        onChange={(ev) => selectionCheck(ev)}
                                                        type="date">
                                                    </input>
                                            ): ("")
                                            }

                                            {c.type==="upload" ? (
                                                <>
                                                <label for={c.id}>
                                                    <img src={aPath + c.asset} alt={c.name} className="img-fluid" ></img>
                                                </label>
                                                <input multiple="multiple"
                                                    id={c.id}
                                                    name="filename"
                                                    accept="image/*"
                                                    type="file"
                                                    onClick={(ev) => selectionCheck(ev)}
                                                    className="btn btn-block choice border-Default ">
                                                </input>
                                                </>
                                            ): ("")}

                                            {c.type==="input_upload" ?
                                            (
                                            <div className="w-100">
                                                <div className="relative h-40 w-100 flex-fill flex justify-center items-center bg-lightgray ">
                                                    <div className="absolute w-100">
                                                        <div className="flex flex-col items-center">
                                                            <i className="fa fa-upload fa-3x"></i>
                                                            <span className="">Hier klicken und Bild auswählen</span>
                                                        </div>
                                                    </div>
                                                    <input id={c.id} className="choice border-Default h-100 w-100 opacity-0 cursor-pointer" multiple="multiple" accept="image/*" type="file" >

                                                    </input>
                                                </div>
                                                <div className="mt-8">
                                                    <div className="flex flex-wrap -m-2">
                                                    </div>
                                                </div>
                                            </div>

                                            ): ("")
                                            }

                                            {c.type==="select" ?
                                            (
                                                <select
                                                    id={c.id}
                                                    className="choice form-control"
                                                    value={c.value ? c.value : ""}
                                                    onChange={(ev) => selectionCheck(ev, true)}
                                                >
                                                    {c.options.map(o => (
                                                    <option value={o}>{o}</option>
                                                    ))
                                                    }
                                                </select>
                                            ): ("")
                                            }


                                            {/* {c.type==="upload" ?
                                            (
                                                <input multiple="multiple"
                                                    id={c.id}
                                                    accept="image/*"
                                                    type="file"
                                                    className="btn choice border-Default h-100 w-100 opacity-0 cursor-pointer">
                                                </input>
                                            ): ("")
                                            } */}
                                        </div>
                                    </div>
                                ))
                                }
                            </div>
                        </div>
                    </div>
                )
                )
            }
        </div>
    )
}

export default DynamicLoader
