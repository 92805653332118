import { useEffect } from "react";
import DynamicLoader from "./DynamicLoader";

const Style = ({selections, setSelections, colorCheck, selectionCheck, checkForward}) => {


    useEffect(() => {
        colorCheck();
        //console.log("pageInc ", page, navArray[page]);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div>

            <DynamicLoader 
                s={selections}
                pageID={2}
                selectionCheck = { (data) => selectionCheck(data) } 
            />


            {/* <div>
                <h1>Welche Küchen gefallen Ihnen am besten?</h1>
            </div>
            <div className="flex flex-wrap justify-center -m-05 mx-4">
                <div className="flex flex-wrap flex-col p-05">
                    <h4>Landhausstil-Küchen</h4>
                    <div>
                        <button type="button"
                            className="choice cursor-pointer border-Default"
                            id="3.0.0"
                            onClick={(ev) => selectionCheck(ev)}    
                        >
                            <img src={image1} alt=""></img>
                        </button>
                    </div>
                </div>
                <div className="flex flex-wrap flex-col p-05">
                    <h4>Moderne Küchen</h4>
                    <div>
                        <button type="button"
                            className="choice cursor-pointer border-Default"
                            id="3.0.1"
                            onClick={(ev) => selectionCheck(ev)}
                        >
                            <img src={image2} alt=""></img>
                        </button>
                    </div>
                </div>
                <div className="flex flex-wrap flex-col p-05">
                    <h4>Grifflose Küchen</h4>
                    <div>
                        <button type="button"
                            className="choice cursor-pointer border-Default"
                            id="3.0.2"
                            onClick={(ev) => selectionCheck(ev)}
                        >
                            <img src={image3} alt=""></img>
                        </button>
                    </div>
                </div>
                <div className="flex flex-wrap flex-col p-05">
                    <h4>Klassische Küchen</h4>
                    <div>
                        <button type="button"  
                            className="choice cursor-pointer border-Default"
                            id="3.0.3"
                            onClick={(ev) => selectionCheck(ev)}
                        >
                            <img src={image4} alt=""></img>
                        </button>
                    </div>
                </div>
                <div className="flex flex-wrap flex-col p-05">
                    <h4>Design Küchen</h4>
                    <div>
                        <button type="button"
                            className="choice cursor-pointer border-Default"
                            id="3.0.4"
                            onClick={(ev) => selectionCheck(ev)}
                        >
                            <img src={image5} alt=""></img>
                        </button>
                    </div>
                </div>
                <div className="flex flex-wrap flex-col p-05">
                    <h4>Industrial Küchen</h4>
                    <div>
                        <button type="button"
                            className="choice cursor-pointer border-Default"
                            id="3.0.5"
                            onClick={(ev) => selectionCheck(ev)}
                        >
                            <img src={image6} alt=""></img>
                        </button>
                    </div>
                </div>
                <div className="flex flex-wrap flex-col p-05">
                    <h4>Holz-Küchen</h4>
                    <div>
                        <button type="button"
                            className="choice cursor-pointer border-Default"
                            id="3.0.6"
                            onClick={(ev) => selectionCheck(ev)}
                        >
                            <img src={image7} alt=""></img>
                        </button>
                    </div>
                </div>
                <div className="flex flex-wrap flex-col p-05">
                    <h4>Eigener Vorschlag</h4>
                    <div>
                        <button type="button"
                            className="choice cursor-pointer border-Default"
                            id="3.0.7"
                            onClick={(ev) => selectionCheck(ev)}
                        >
                            <img src={image8} alt=""></img>
                        </button>
                        <input multiple="multiple" 
                            accept="image/*" 
                            type="file" 
                            className="h-full w-full opacity-0 cursor-pointer">
                        </input> 
                    </div>
                </div>
            </div>  */}

        </div>
    )
}

export default Style
