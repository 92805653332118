import { useEffect } from "react";

import DynamicLoader from "./DynamicLoader";

const Data = ({selections, setSelections, colorCheck, selectionCheck, checkForward}) => {


    // useEffect(() => {
    //     colorCheck();
    //     //console.log("pageInc ", page, navArray[page]);
    // }, []) // eslint-disable-line react-hooks/exhaustive-deps



    return (
        <div>
            {console.log("DATA ",selections.data)}
            <DynamicLoader 
                s={selections}
                pageID={5}
                selectionCheck = { (data) => selectionCheck(data) } 
            />

            {/* <div>
                <h1>Angaben zum neuen Küchen(t)raum</h1>
                <p>Für Ihr unverbindliches Angebot benötigen wir die groben Maße oder Bilder Ihres Raumes</p>
            </div>
            <div className="flex flex-wrap flex-col p-05">
                <label>
                    <textarea rows="8"
                        id="6.0.0" 
                        className="btn choice b form-textarea"
                        value={selections.pages[6].questions[0].choices[0].value ? selections.pages[6].questions[0].choices[0].value : ""}
                        onChange={(ev) => selectionCheck(ev, true)}
                    >
                    </textarea>
                </label>
            </div>
            <div className="flex flex-wrap flex-col p-05">
                <label>Raumplan hochladen (optional)</label>
                <input multiple="multiple" 
                    id="6.0.1"
                    accept="image/*" 
                    type="file" 
                    className="btn choice border-Default h-full w-full opacity-0 cursor-pointer">
                </input>    
            </div>              */}
        </div>
    )
}

export default Data
