import PropTypes from 'prop-types'


const Button = ({bgcolor, color, text, onClick, name, disabled, className}) => {
 
    return (
        <button 
            onClick={onClick} 
            style={{backgroundColor: bgcolor, color:color}} 
            className= { className } //+ }'btn p-05  cursor-pointer'}
            disabled={disabled}
            name={name}
            
        >
            {text}
        </button>
    )
}

Button.defaultProps = {
    //color: 'orange'
}

Button.propTypes = {
    text: PropTypes.string,
    color: PropTypes.string,
    onClick: PropTypes.func,
}

export default Button