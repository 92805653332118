import { useEffect } from "react";
import DynamicLoader from "./DynamicLoader";


// TODO: make component Form a class, with constructor etc.
//otherwise we can't use the event'

//class Form extends React.Component {

// all content in an array, enables also easy choice collection at submit

// const content = [
//     {
//         "h4":"Einzeilig",
//         "img_src":"1-einzeilig.png"
//     },
//     {
//         "h4":"Zweizeilig",
//         "img_src":"1-zweizeilig.png"
//     },
// ]
const pageID = 0;

const Form = ({disableForwardBtn, s, setS, colorCheck, selectionCheck, checkForward}) => {
    //const assets = require('../assets/images/1-form/1-einzeilig.png').default;
    const aPath = s.pages[pageID].assets_path;
    

    // function get_choices_html(quest) {
    //     const choices = quest.choices
    //     let return_html = ''
    //     choices.forEach ((c, index) => {
    //         return_html += 
    //         `
    //         <div className="flex flex-col p-05">
    //             <h4 className="text-center">${c.name}</h4>
    //             <div>
    //                 <button 
    //                     className="choice cursor-pointer border-Default w-full hover:shadow-lg focus:outline-none" 
    //                     type="button"
    //                     id=${c.id}
    //                     onClick={(ev) => selectionCheck(ev)}
                        
    //                     ${c.asset ? 
    //                         `<img   src="${aPath + c.asset}" 
    //                                 alt="${c.name}">
    //                         </img>` 
    //                         :`${c.name} >`}
    //                 </button>
    //             </div>
    //         </div>
    //         `
    //     });
    //     //console.log(return_html);
    //     //return {__html: return_html};

    //     return( 
    //         choices.map (c => 
    //         <div className="flex flex-col p-05">
    //             <h4 className="text-center">{c.name}</h4>
    //             <div>
    //                 <button 
    //                     className="choice cursor-pointer border-Default w-full hover:shadow-lg focus:outline-none" 
    //                     type="button"
    //                     id={c.id}
    //                     onClick={(ev) => selectionCheck(ev)}
    //                     >
    //                     {c.asset ? ("") : (<span>{c.name}</span>) }
    //                     {c.asset ? (<img src={aPath + c.asset} alt={c.name}></img>) : "" }
    //                 </button>
    //             </div>
    //         </div>
    //     )
    //     )
    // }


    // function get_choices(quest) {
    //     const choices = quest.choices
    //     return(
    //         choices.map(c => 
    //             <div className="flex flex-col p-05">
    //                 <h4 className="text-center">{c.name}</h4>
    //                 {<div>
    //                     <button 
    //                         className="choice cursor-pointer border-Default w-full hover:shadow-lg focus:outline-none" 
    //                         type="button"
    //                         id={c.id}
    //                         onClick={(ev) => selectionCheck(ev)}>
    //                         <img 
    //                             src={aPath + c.asset} 
    //                             alt={c.name}></img>
                            
    //                     </button>
    //                 </div>}
    //             </div>
    //         )
    //     );
    // }

    useEffect(() => {
        colorCheck();
        //console.log("pageInc ", page, navArray[page]);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

       
//for(var qInd=0; q; q=s.pages[pageID].questions; qInd++) {
    return (
        <div>
            
            <DynamicLoader 
                s={s}
                pageID={0}
                selectionCheck = { (data) => selectionCheck(data) } 
            />
            {/* <div>
                <h1>Welche Form soll die Küche haben?</h1>
            </div>
            <div className="flex flex-wrap justify-center -m-05 mx-4 ">
                <div className="flex flex-col p-05">
                    <h4 className="text-center">Einzeilig</h4>
                    <div>
                        <button 
                            className="choice cursor-pointer border-Default w-full hover:shadow-lg focus:outline-none" 
                            type="button"
                            id="0.0.0"
                            onClick={(ev) => selectionCheck(ev)}>
                            <img src={image1} alt="Einzeilig"></img>
                        </button>
                    </div>
                </div>
                <div className="flex flex-wrap flex-col p-05">
                    <h4 className="text-center">Zwei Küchenzeilen</h4>
                    <div>
                        <button className="choice cursor-pointer border-Default" 
                            type="button"
                            id="0.0.1"
                            onClick={(ev) => selectionCheck(ev)}>
                            <img src={image2} alt="Zwei Küchenzeilen"></img>
                        </button>
                    </div>
                </div>
                <div className="flex flex-wrap flex-col p-05">
                    <h4 className="text-center">L-Form</h4>
                    <div>
                        <button className="choice cursor-pointer border-Default" 
                            type="button"
                            id="0.0.2"
                            onClick={(ev) => selectionCheck(ev)}>
                            <img src={image3} alt="L-Form"></img>
                        </button>
                    </div>
                </div>
                <div className="flex flex-wrap flex-col p-05">
                    <h4 className="text-center">U-Form</h4>
                    <div>
                        <button className="choice cursor-pointer border-Default" 
                            type="button"
                            id="0.0.3"
                            onClick={(ev) => selectionCheck(ev)}>
                            <img src={image4} alt="U-Form"></img>
                        </button>
                    </div>
                </div>
                <div className="flex flex-wrap flex-col p-05">
                    <h4 className="text-center">Mit Kücheninsel</h4>
                    <div>
                        <button className="choice cursor-pointer border-Default" 
                            type="button"
                            id="0.0.4"
                            onClick={(ev) => selectionCheck(ev)}>
                            <img src={image5} alt="Mit Kücheninsel"></img>
                        </button>
                    </div>
                </div>
                <div className="flex flex-wrap flex-col p-05">
                    <h4 className="text-center">Küche mit Theke</h4>
                    <div>
                        <button className="choice cursor-pointer border-Default" 
                            type="button"
                            id="0.0.5"
                            onClick={(ev) => selectionCheck(ev)}>
                            <img src={image6} alt="Küche mit Theke"></img>
                        </button>
                    </div>
                </div>
            </div>

            <div id="seprator" className="border border-Default my-35"></div>    
            
            <div>
                <h1>Möchten Sie eine offenen oder eine seperate Küche?</h1>
            </div>
            <div className="flex flex-wrap justify-center -m-05 mx-4 ">
                <div>
                    <button 
                        type="button" 
                        id="0.1.0"
                        onClick={(ev) => selectionCheck(ev)}
                        className= "choice border-Default cursor-pointer focus:outline-none flex items-center">
                        <span 
                            className="">
                            Offene Wohnküche
                        </span>
                    </button>
                </div>
                <div>
                    <button 
                    type="button"
                    id="0.1.1" 
                    onClick={(ev) => selectionCheck(ev)}
                    className= "choice cursor-pointer border-Default">
                        <span>Seperater Küchenraum</span>
                    </button>
                </div>
            </div> */}


            {/* <button onClick={() => setSelections()}      
                className="state "
            >state
            </button>
            <button onClick={checkForward}      
                className="test "
            >Toggle
            </button> */}
        </div>
    );
}

export default Form;
