import { useEffect } from "react";

import DynamicLoader from "./DynamicLoader";


const Budget = ({selections, setSelections, colorCheck, selectionCheck, checkForward}) => {

    useEffect(() => {
        colorCheck();
        //console.log("pageInc ", page, navArray[page]);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div>

            <DynamicLoader 
                s={selections}
                pageID={4}
                selectionCheck = { (data) => selectionCheck(data) } 
            />
            {/* <div>
                <h1>Wie hoch ist Ihr gewünschtes Budget?</h1>
            </div>
            <div className=" p-05">
                <button type="button"
                    className="choice cursor-pointer border-Default p-05"
                    id="5.0.0"
                    onClick={(ev) => selectionCheck(ev)}
                >
                    <span>unter 4.000€</span>
                </button>
            </div>
            <div className=" p-05">
                <button type="button" 
                    className="choice cursor-pointer border-Default p-05"
                    id="5.0.1"
                    onClick={(ev) => selectionCheck(ev)}
                >
                    <span>4.000€-7.000€</span>
                </button>
            </div>  
            <div className=" p-05">
                <button type="button"
                    className="choice cursor-pointer border-Default p-05"
                    id="5.0.2"
                    onClick={(ev) => selectionCheck(ev)}
                >
                    <span>7.000€-12.000€</span>
                </button>
            </div>  
            <div>
                <button type="button"
                    className="choice cursor-pointer border-Default p-05"
                    id="5.0.3"
                    onClick={(ev) => selectionCheck(ev)}
                >
                    <span>12.000€-16.000€</span>
                </button>
            </div>  
            <div className=" p-05">
                <button type="button"
                    className="choice cursor-pointer border-Default p-05"
                    id="5.0.4"
                    onClick={(ev) => selectionCheck(ev)}
                >
                    <span>16.000€-20.000€</span>
                </button>
            </div>  
            <div className=" p-05">
                <button type="button"
                    className="choice cursor-pointer border-Default p-05"
                    id="5.0.5"
                    onClick={(ev) => selectionCheck(ev)}
                >
                    <span>20.000€ und mehr</span>
                </button>
            </div>                */}
        </div>
    )
}

export default Budget
