import Header from './components/Header'
import "iframe-resizer/js/iframeResizer.contentWindow.js"
import Navigation from './components/Navigation'
//import Tabs from "./components/Tabs";
import {BrowserRouter as Router, Route, Link } from 'react-router-dom'
import { useState } from "react";
import { useEffect } from "react";
import * as fs from 'fs';
//import {readFileSync} from 'fs';

//import { useLocation} from 'react-router-dom'
//import { useHistory } from 'react-router-dom';

//import selections_json from "./selections"
import selections_no_id_json from "./selections_no_id"

import Form from './components/Form'
import Persons from './components/Persons'
//import Color from './components/Color'
import Style from './components/Style'
import Wishes from './components/Wishes'
import Budget from './components/Budget'
import Data from './components/Data'
import Contact from './components/Contact'
//import DynamicLoader from './components/DynamicLoader'


import './App.css';
import { get } from 'http';


// const navArray = [
//   "/",
//   "Form",
//   "Persons",
//   "Color",
//   "Style",
//   "Wishes",
//   "Budget",
//   "Data",
//   "Contact"
// ]

const App = () =>{

  const DEBUG = false

  const API_HOST = "http://127.0.0.1/api"

  const queryParams = new URLSearchParams(window.location.search);
  const q_client = queryParams.get('client');
  const q_quest = queryParams.get('questionnaire');
  console.log("URL PARAMS client", q_client);
  console.log("URL PARAMS quest", q_quest);
  let selection_raw = null

  // if(selection_raw) {
  //   db_get(`client/4/questionnaire/1`)
  //   selection_raw = 1
  // }
  // else{
  //   console.log(`URL PARAMS quest ${q_quest}  client ${q_client}`);
  // }
  // get_config()
  // function get_config() {
  //   let x= db_get(`client/4/questionnaire/1`)
  //   console.log("GET CONFIG ", x)
  // }



  indexer(selections_no_id_json)
  //const location = useLocation();
  // const [selections, setSelections] = useState(selections_json)
  // const [s, setS] = useState(selections_json)
  const [selections, setSelections] = useState(selections_no_id_json)
  const [s, setS] = useState(selections_no_id_json)

  const [forwardBtn, setForwardBtn] = useState(true)
  const [page, setPage] = useState(1)

  // Generate leads ID based on UNIX timestamp
  console.log("selections.id: "+ selections.id)
  if( ! selections.id ) {
    selections.id = (new Date()).getTime().toString(16);
    console.log("selections.id: "+ selections.id)
  }




  const dev_json =
    {"client_json":{
        "name": "App POST Test",
        "logo_path": "/testclient/logo.jpg",
        "css_path": "/testclient/main.css",
        "questions_json": "Please insert questions_JSON"
      }
      ,
      "lead_json":{
          "client_id": 7,
          "lead_json": "lead_results_json_placeholder"
      }
    };


  function exportSelection(){
    var expStr = ""
    expStr += "Version: " + selections.version + "\nLead-ID: " + selections.id + "\n"
    for(var page=0; page < selections.pages.length; page++) {
      for(var question=0; question < selections.pages[page].questions.length; question++) {
        expStr += "\n" + selections.pages[page].questions[question].title + "\n"
        for(var choice=0; choice < selections.pages[page].questions[question].choices.length; choice++) {
          if( selections.pages[page].questions[question].choices[choice].value ) {
            if (selections.pages[page].questions[question].choices[choice].value === true ) {
              expStr += "\t- " + selections.pages[page].questions[question].choices[choice].name + "\n"
            }
            else {
              expStr += "\t" + selections.pages[page].questions[question].choices[choice].name + "\n"
              expStr += "\t\t- " + selections.pages[page].questions[question].choices[choice].value + "\n"
            }
          }
        }
      }
    }
    console.log(expStr)
  }

  function incButton(sig) {
        console.log("btn_page", page, sig)
        let actualPage = page;
        actualPage >= 8 ? actualPage = 8 : setPage(actualPage => actualPage + 1);
        //console.log("incBtn_loc: ", location.pathname)
        //history.push(navArray[page]);
        scrollToTop();
  }

  function decButton() {
      console.log("btn_page", page)
      let actualPage = page;
      actualPage <= 1 ? actualPage = 8 : setPage(actualPage => actualPage - 1);
      scrollToTop();
  }

  function scrollToTop() {
    if ('parentIFrame' in window) {
      window.parentIFrame.scrollTo(0, 0)
    }
  }

  function disableForwardBtn(state) {
    var actualforwardBtn = forwardBtn;
    setForwardBtn((actualForwardBtn) => actualForwardBtn = state )
  }


  function colorCheck (elem_id) {
    var btns, pi, id

    if(elem_id) {
      id = elem_id
    }
    else {
      btns = document.getElementsByClassName("choice");
      id = btns[0].id
    }
    pi = id.indexOf('.', 0)
    const page = idToInt(id)[0] //parseInt(id.substring(0 , pi))
    var i, n;
    const pages = selections.pages[page]
    //console.log("colC id: ", id);

    // let navLink = document.getElementById(page)
    // if( navLink.className.includes("disabled-link") ) {
    //     navLink.className = navLink.className.replace("disabled-link", "");
    // }
    for( i=0; i < pages.questions.length; i++) {
        for( n=0; n < pages.questions[i].choices.length; n++) {
            //console.log("colC ", n, id);
            var btn = document.getElementById(pages.questions[i].choices[n].id)
            if (btn.className.includes("border-Active") || btn.className.includes("border-Default")) {
              if (pages.questions[i].choices[n].value === true ) {
                  btn.className = btn.className.replace(" border-Default", " border-Active");
              }
              else {
                  btn.className = btn.className.replace(" border-Active", " border-Default");
              }
            }
        }
    }
    if(page===2 ) {
      let tggl = document.getElementsByClassName("toggle")[0]
      if( tggl.className.includes("border-Active") ) {
        let upl = document.getElementsByClassName("upload_show")[0]
        if ( upl.className.includes("d-none") ) {
          upl.className = upl.className.replace(" d-none", " d-block");
        }
      }
    }
  }

  function idToInt(id) {
    var pi, qi, ci
    pi = id.indexOf('.', 0)
    const p = parseInt(id.substring(0 , pi))
    qi = id.indexOf('.', pi+1)
    const q = parseInt(id.substring(pi+1, qi))
    ci = id.indexOf('.', qi+1)
    const c = parseInt(id.substring(qi+1, id.length))
    return ([p, q, c])
  }


  function selectionCheck(ev, text) {
    var pi, qi
    const id = ev.currentTarget.id
    const intID = idToInt(id)
    const page = intID[0]
    const question = intID[1]
    const choice = intID[2]
    const questions = selections.pages[page].questions[question]
    console.log("sC " , questions.max_answers)



    if ( questions.max_answers === "1" ) {
      for(var i = 0; i < questions.choices.length; i++ ){
        questions.choices[i].value=false
      }
      questions.choices[choice].value=true
    } else {
      if (ev.type === "change") { // for text inputs
        questions.choices[choice].value = ev.currentTarget.value
      }
      else if (ev.type === "click"){ // for buttons
        questions.choices[choice].value
        = !questions.choices[choice].value
      }
    }
    if (page===0 && question===0) {
      selections.data = [questions.choices[choice].asset, questions.choices[choice].num_meas]
    }
    if(page===2 && question===0 ) {
      let upl = document.getElementsByClassName("upload_show")[0]
      if (ev.currentTarget.getAttribute("act")==="toogle") {
        if ( upl.className.includes("d-none") ) {
          upl.className = upl.className.replace(" d-none", " d-block");
        }
      }
      else if ( upl.className.includes("d-block") ) {
        upl.className = upl.className.replace(" d-block", " d-none");
      }
    }

    if(ev.target.files) {
      if(ev.target.files[0]) {
        //console.log("SELECTIONS-file-name ", ev.target.files[0].name)
        questions.choices[choice].value = ev.target.files[0].name
        //console.log("SELECTIONS-selections ", questions.choices[choice].value)
      }
    }

    setSelections({
    ...selections, choices:selections.choices
    })
    //console.log("cC2 ",selections.pages[page].questions[question].choices[choice].value);
    colorCheck(id);
    checkForward(selections.pages[page].questions, page);
}


  function checkForward(questions, page) {
    let counter = Array(questions.length).fill(0);
    let forW = Array(questions.length).fill(false);
    //let list = document.getElementsByClassName("choice");
    for(let qI = 0; qI < questions.length; qI++ ){
        for(let cI = 0; cI < questions[qI].choices.length; cI++) {
            if(questions[qI].choices[cI].value)
                counter[qI]+=1;
        }
        console.log("min/max answers: ",questions[qI].min_answers, questions[qI].max_answers,counter[qI])
        if( counter[qI] >= questions[qI].min_answers &&
            counter[qI] <= questions[qI].max_answers) {
            forW[qI] = true;
        }
        else if( questions[qI].min_answers === 0 && questions[qI].max_answers === 0 ){
          //forW[qI] = true;
        }
    }
    console.log("selec", forW)
    console.log("selec2", forW.every((e) => e===true) )
    if ( forW.every((e) => e===true) ) {
        selections.pages[page].disable_forward = false;
        //let li = document.getElementsByName("forward");
        //li[0].disabled = false;
        //console.log(li[0]);
        console.log("enable fB", selections.pages[page].disable_forward)
        //disableForwardBtn(false);
    }
  }

  //const forwardBtn = document.getElementsByName("forward");
  //console.log(forwardBtn)
  //forwardBtn.addEventListener('click', toogleDisabled());

  //useEffect(() => {
    //     //console.log("pageInc ", page, navArray[page]);
    //     console.log(page)
    //     //console.log(page)
    //     //history.push(navArray[page]);
    //    // incButton();
    // }, [page]);

  function indexer(sjs) {
    try {
      for(var p=0; p < sjs.pages.length; p++) {
        for(var q=0; q < sjs.pages[p].questions.length; q++) {
          for(var c=0; c < sjs.pages[p].questions[q].choices.length; c++) {
            sjs.pages[p].questions[q].choices[c].id = `${p}.${q}.${c}`;
          }
        }
      }
    } catch (error) {
      console.error(`Got an error trying to read the selections.json file: ${error.message}`);
    }
  }




  function db_get(endpoint){
    console.log("GET " + API_HOST);
    let test;
    fetch(API_HOST + "/" + endpoint)
      .then(data => {
        test = data.json()

      })
      .then(res => {
          console.log("GET REQUEST: ", test);
          return res;
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log(error);
        }
      )
      return test
  }



  function db_post(endpoint, req_json){
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(req_json)
    };
    fetch(API_HOST + '/' + endpoint, requestOptions)
      .then(response => console.log(response.json()));
  }


  function db_put(endpoint, id, req_json){
    const dev_json =
      {"client_json":{
          "name": "App PUT Test",
          "logo_path": "/testclient/logo.jpg",
          "css_path": "/testclient/main.css",
          "questions_json": "Please insert questions_JSON"
        }
      ,
      "lead_json":{
          "client_id": 7,
          "lead_json": "PUT TEST lead_results_json_placeholder"
      }
    };
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(dev_json[req_json])
    };
    fetch(API_HOST + '/' + endpoint + "/" + id, requestOptions)
      .then(response => console.log(response));
  }



  let linkClass = DEBUG ? "flex-fill py-2 mt-0 mr-1 font-weight-bold btn-deactive rounded-0 text-white text-center text-decoration-none "
          : "disabled-link flex-fill py-2 mt-0 mr-1 font-weight-bold btn-deactive rounded-0 text-white text-center text-decoration-none "

  let navLinkState = []


  return (
    <Router>
      {//exportSelection()

      }
      <div className="px-3">



          {/*

          <button onClick={()=> db_get("leads") }
            className="Dev p-02"
          >GET Leads
          </button>

          <button onClick={()=> db_get("clients") }
            className="Dev p-02"
          >GET Clients
          </button>

          <button onClick={()=> db_get("questionnaires") }
            className="Dev p-02"
          >GET Quests
          </button>

          <button onClick={()=> db_get(`client/4/questionnaire/1`)}
            className="Dev p-02"
          >GET Q 1
          </button>

          <button onClick={()=> db_post("leads", dev_json["lead_json"]) }
            className="Dev p-02"
          >POST Leads
          </button>

          <button onClick={()=> db_post("clients", dev_json["client_json"]) }
            className="Dev p-02"
          >POST Clients
          </button>

          <button onClick={()=> db_put("leads", "9","lead_json") }
            className="Dev p-02"
          >PUT Leads
          </button>

          <button onClick={()=> db_put("clients", "7","client_json") }
            className="Dev p-02"
          >PUT Clients
          </button>

          <button onClick={()=> indexer(selections_no_id_json) }
            className="Dev p-02"
          >Indexer
          </button> */}



        {/* <Header title='Lead-App'/> */}


        <div className="d-flex">
            <Link className={linkClass}         id="0" to='/Form'     onClick={() => setPage(1)}>1. <span class="d-none d-sm-inline">Form</span></Link>
            <Link className={linkClass}         id="1" to='/Persons'  onClick={() => setPage(2)}>2. <span class="d-none d-sm-inline">Personen  </span></Link>
            <Link className={linkClass}         id="2" to='/Style'    onClick={() => setPage(3)}>3. <span class="d-none d-sm-inline">Küchenstil</span></Link>
            <Link className={linkClass}         id="3" to='/Wishes'   onClick={() => setPage(4)}>4. <span class="d-none d-sm-inline">Wünsche   </span></Link>
            <Link className={linkClass}         id="4" to='/Budget'   onClick={() => setPage(5)}>5. <span class="d-none d-sm-inline">Budget    </span></Link>
            <Link className={linkClass}         id="5" to='/Data'     onClick={() => setPage(6)}>6. <span class="d-none d-sm-inline">Angaben   </span></Link>
            <Link className={linkClass}         id="6" to='/Contact'  onClick={() => setPage(7)}>7. <span class="d-none d-sm-inline">Kontakt   </span></Link>
        </div>



        <Route exact path='/Form' render={() => <Form
          disableForwardBtn={(state) => disableForwardBtn(state)}
          s={selections}
          setS={(data) => setSelections(data)}
          colorCheck= { () => colorCheck() }
          selectionCheck = { (data) => selectionCheck(data) }
          checkForward = { (questions, page) => checkForward(questions, page) }

          /> }
        />
        <Route exact path='/Persons' render={() => <Persons
          //disableForwardBtn={() => disableForwardBtn()}
          selections={selections}
          setSelections={(data) => setSelections(data)}
          colorCheck= { () => colorCheck() }
          selectionCheck = { (data) => selectionCheck(data) }
          checkForward = { (questions, page) => checkForward(questions, page) }
          /> }
        />
        <Route exact path='/Style' render={() => <Style
          //disableForwardBtn={() => disableForwardBtn()}
          selections={selections}
          setSelections={(data) => setSelections(data)}
          colorCheck= { () => colorCheck() }
          selectionCheck = { (data) => selectionCheck(data) }
          checkForward = { (questions, page) => checkForward(questions, page) }
          /> }
        />
        <Route exact path='/Wishes' render={() => <Wishes
          //disableForwardBtn={() => disableForwardBtn()}
          selections={selections}
          setSelections={(data) => setSelections(data)}
          colorCheck= { () => colorCheck() }
          selectionCheck = { (data) => selectionCheck(data) }
          checkForward = { (questions, page) => checkForward(questions, page) }
          /> }
        />
        <Route exact path='/Budget' render={() => <Budget
          //disableForwardBtn={() => disableForwardBtn()}
          selections={selections}
          setSelections={(data) => setSelections(data)}
          colorCheck= { () => colorCheck() }
          selectionCheck = { (data) => selectionCheck(data) }
          checkForward = { (questions, page) => checkForward(questions, page) }
          /> }
        />
        <Route exact path='/Data' render={() => <Data
          //disableForwardBtn={() => disableForwardBtn()}
          selections={selections}
          setSelections={(data) => setSelections(data)}
          colorCheck= { () => colorCheck() }
          selectionCheck = { (data) => selectionCheck(data) }
          checkForward = { (questions, page) => checkForward(questions, page) }
          /> }
        />
        <Route exact path='/Contact' render={() => <Contact
          //disableForwardBtn={() => disableForwardBtn()}
          selections={selections}
          setSelections={(data) => setSelections(data)}
          colorCheck= { () => colorCheck() }
          selectionCheck = { (data) => selectionCheck(data) }
          checkForward = { (questions, page) => checkForward(questions, page) }
          /> }
        />


        <Navigation
          page={page}
          incButton={ () => incButton() }
          decButton={ () => decButton() }
          disableForwardBtn={ (state) => disableForwardBtn(state) }
          forwardBtn = { forwardBtn }
          selections = { selections }
        />
      </div>
    </Router>
  );
}

export default App;
