import { useEffect } from "react";
import DynamicLoader from "./DynamicLoader";

const Persons = ({selections, setSelections, colorCheck, selectionCheck, checkForward}) => {

    const pageID = 1;

    useEffect(() => {
        colorCheck();
        //console.log("pageInc ", page, navArray[page]);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div>

            <DynamicLoader 
                s={selections}
                pageID={1}
                selectionCheck = { (data) => selectionCheck(data) } 
            />

            {/* <div>
                <h1>Wie viele Personen leben bei Ihnen zuhause?</h1>
            </div>   
            <div className="flex flex-wrap justify-center -m-05 mx-4">
                <div className="flex flex-wrap flex-col p-05">
                    <h4>Allein</h4>
                    <div>
                        <button type="button"
                            className="choice cursor-pointer border-Default"
                            id="1.0.0"
                            onClick={(ev) => selectionCheck(ev)}
                        >
                            <img src={image1} alt=""></img>
                        </button>
                    </div>
                </div>
                <div className="flex flex-wrap flex-col p-05">
                    <h4>Zwei</h4>
                    <div>
                        <button type="button"
                            className="choice cursor-pointer border-Default"
                            id="1.0.1"
                            onClick={(ev) => selectionCheck(ev)}
                        >
                            <img src={image2} alt=""></img>
                        </button>
                    </div>
                </div>
                <div className="flex flex-wrap flex-col p-05">
                    <h4>Drei</h4>
                    <div>
                        <button type="button"
                            className="choice cursor-pointer border-Default"
                            id="1.0.2"
                            onClick={(ev) => selectionCheck(ev)}
                        >
                            <img src={image3} alt=""></img>
                        </button>
                    </div>
                </div>
                <div className="flex flex-wrap flex-col p-05">
                    <h4>Vier oder mehr</h4>
                    <div>
                        <button type="button"
                            className="choice cursor-pointer border-Default"
                            id="1.0.3"
                            onClick={(ev) => selectionCheck(ev)}
                        >
                            <img src={image4} alt=""></img>
                        </button>
                    </div>
                </div>
            </div>          */}
        </div>
    )
}

export default Persons
